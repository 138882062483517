import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import HeroCarouselImage from "../images/home/home-carousel-image.png";
import CustomerSatisfyImage from "../images/home/customer-satisfy-img.png";
import MordenContentImage from "../images/home/morden-content-managment.png";
import FeaturesCmsIcon from "../assets/icons/features-cms-icon.svg";
import FeaturesBuilderIcon from "../assets/icons/features-builder-icon.svg";
import FeaturesDeliveryIcon from "../assets/icons/features-delivery-icon.svg";
import FeaturesModelCubeIcon from "../assets/icons/features-model-cube-icon.svg";
import ProductBoostOverview from "../images/home/product-boost-overview.png";
import FeaturesSearchIcon from "../assets/icons/features-search-status-icon.svg";
import FeaturesAnalyticsIcon from "../assets/icons/features-search-analytics-icon.svg";
import FeaturesBubbleIcon from "../assets/icons/features-bubble-icon.svg";
import FeaturesShopIcon from "../assets/icons/features-shop-icon.svg";
import PersonalizedWidgetImage from "../images/home/personalized-widget-image.png";
import FeaturesFolderIcon from "../assets/icons/features-folder-icon.svg";
import FeaturesRefreshCircleIcon from "../assets/icons/features-refresh-circle-icon.svg";
import FeaturesSmartSegmentationIcon from "../assets/icons/features-smart-segmentation-icon.svg";
import FeaturesShieldSecurityIcon from "../assets/icons/features-shield-security-icon.svg";
import PersonalizedWidgetAutomation from "../images/home/personalized-widget-automation.png";
import FeaturesShareIcon from "../assets/icons/features-share-icon.svg";
import FeaturesChartIcon from "../assets/icons/features-chart-icon.svg";
import FeaturesMouseIcon from "../assets/icons/features-mouse-icon.svg";
import DesignIcon from "../assets/icons/designer-icon.svg";
import MultiWorkspaceIcon from "../assets/icons/multi-workspace-icon.svg";
import TargetIcon from "../assets/icons/target-icon.svg";
import OrderSuccessfullyImg from "../images/home/order-successfully-img.png";

const IndexPage = () => {

    return (
        <div className="template-home header-white header-position">
            <Helmet>
                <title>Best Digital Experience Platform in 2022 - Experro</title>
                <meta name="description" content="Looking for a digital experience platform? Your search ends with Experro - The best digital experience platform. *Try Now*"/>
            </Helmet>
            <Layout>
                <div className="hero-carousel mb-208">
                    <div className="container">
                        <h6 className="sub-heading text-center">DIGITAL experience platform</h6>
                        <h2 className="h1 font-white text-center mb-24">Deliver hyper-personalized <br /> experience to each customer</h2>
                        <p className="text-center mb-48">A single platform to create unforgettable digital experiences, grow sales, and foster loyalty with powerful content, AI- <br /> powered commerce, and personalized customer journey across all channels</p>
                        <div className="text-center">
                            <Link className="button right-arrow black-button" to="/contact/">Get a Demo</Link>
                        </div>
                        <div className="image-block">
                            <img loading="lazy" src={HeroCarouselImage} title="Hero Carousel" alt="Hero Carousel" />
                        </div>
                    </div>
                </div>

                <div className="customer-satisfy-block mb-208">
                    <div className="container">
                        <h6 className="sub-heading primary-light-color">THE BIGGEST BARRIER TO BUSINESS GROWTH</h6>
                        <h3 className="font-medium mb-80">Most customer experiences today are <br /> impersonal and broken - here is why</h3>
                        <div className="custom-row d-flex row-2 align-v-center">
                            <div className="grid">
                                <div className="image-block">
                                    <img loading="lazy" src={CustomerSatisfyImage} title="Customer Satisfy" alt="Customer Satisfy" />
                                </div>
                            </div>
                            <div className="grid">
                                <div className="text-block">
                                    <ul className="list-style-none list-with-bullet">
                                        <li>
                                            <h6>Customers are not well understood</h6>
                                            <p>The lack of rich customer interaction and intent data prevents businesses from understanding customers’ journeys at scale.</p>
                                        </li>
                                        <li>
                                            <h6>Content does not satisfy customer intent</h6>
                                            <p>One-size-fits-all content is not able to live up to the fast-changing customer expectations.</p>
                                        </li>
                                        <li>
                                            <h6>Commerce is not personalized to customer’s taste</h6>
                                            <p>Irrelevant browsing and shopping experiences do not engage customers or inspire actions.</p>
                                        </li>
                                        <li>
                                            <h6>Competitors are winning your customers</h6>
                                            <p>No matter how hard your team tries, the hard-earned customers continue to flock to competitors who cater to their intent and taste.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="experro-business-cycle mb-208">
                    <div className="container">
                        <h3 className="font-medium line-height-60 mb-80">Experro enables businesses to connect the dots across <br /> content, commerce, and customer data</h3>
                        <div className="overflow-block">
                            <div className="custom-row d-flex row-4">
                                <div className="grid">
                                    <Link to="/cms/" className="business-cycle-item cms-block">
                                        <span>CMS</span>
                                        <span className="big-heading">Content Management</span>
                                    </Link>
                                    <p className="small-text mb-0">Craft fast, modern, and engaging online and mobile experiences.</p>
                                </div>
                                <div className="grid">
                                    <Link to="/discovery/" className="business-cycle-item aiml-block">
                                        <span>AI/ML</span>
                                        <span className="big-heading">Product Discovery</span>
                                    </Link>
                                    <p className="small-text mb-0">Drive engagement, improve conversion and grow sales.</p>
                                </div>
                                <div className="grid">
                                    <Link to="/journey/" className="business-cycle-item cdp-block">
                                        <span>CDP</span>
                                        <span className="big-heading">Customer Data Platform</span>
                                    </Link>
                                    <p className="small-text mb-0">Unleash the power of your customer data.</p>
                                </div>
                                <div className="grid">
                                    <Link to="/journey/#marketing-automation" className="business-cycle-item ma-block">
                                        <span>MA</span>
                                        <span className="big-heading">Marketing Automation</span>
                                    </Link>
                                    <p className="small-text mb-0">Enable hyper-personalized marketing to boost ROI.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="transform-your-buisness">
                    <div className="container">
                        <h3 className="line-height-60 font-medium mb-80">See what Experro can transform your business</h3>
                    </div>

                    <div className="content-with-image left-side-image d-flex align-v-center mb-208">
                        <div className="left-block">
                            <div className="content-inner">
                                <h6 className="sub-heading primary-light-color">MODERN CONTENT MANAGEMENT</h6>
                                <h4 className="h3 mb-24">Craft meaningful and multi-channel content experiences</h4>
                                <p className="mb-48">Leverage a powerful headless and API-first CMS that empowers developers and marketers to design, organize and deliver beautiful and blazing-fast content for web, mobile, and eCommerce experiences.</p>
                                <h6 className="sub-heading font-x-light-color">POPULAR FEATURES</h6>
                                <ul className="list-style-none feature-list mb-32">
                                    <li>
                                        <i class="icon"><FeaturesCmsIcon /></i><span>Headless CMS</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesBuilderIcon /></i><span>Visual Site Builder</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesDeliveryIcon /></i><span>Blazing-fast Delivery</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesModelCubeIcon /></i><span>Flexible Data Model</span>
                                    </li>
                                </ul>
                                <Link className="btn-link right-arrow" to="/cms/">Learn more</Link>
                            </div>
                        </div>
                        <div className="right-block">
                            <img loading="lazy" src={MordenContentImage} title="Morden Content Management" alt="Morden Content Management" />
                        </div>
                    </div>

                    <div className="content-with-image d-flex align-v-center mb-208">
                        <div className="left-block">
                            <div className="content-inner">
                                <h6 className="sub-heading primary-light-color">AI-POWERED SEARCH & MERCHANDIZING</h6>
                                <h4 className="h3 mb-24">Guide shoppers to the most relevant products results</h4>
                                <p className="mb-48">Boost eCommerce growth with enhanced “Product Discovery” features that help your team take control of product display with an AI-powered search algorithm and site-wide merchandising campaigns.</p>
                                <h6 className="sub-heading font-x-light-color">POPULAR FEATURES</h6>
                                <ul className="list-style-none feature-list mb-32">
                                    <li>
                                        <i class="icon"><FeaturesSearchIcon /></i><span>Product Search</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesAnalyticsIcon /></i><span>Search Insights</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesBubbleIcon /></i><span>Semantic Awareness</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesShopIcon /></i><span>Visual Merchandising</span>
                                    </li>
                                </ul>
                                <Link className="btn-link right-arrow" to="/discovery/">Learn more</Link>
                            </div>
                        </div>
                        <div className="right-block">
                            <img loading="lazy" src={ProductBoostOverview} title="Product Boost Overview" alt="Product Boost Overview" />
                        </div>
                    </div>

                    <div className="content-with-image left-side-image d-flex align-v-center mb-208">
                        <div className="left-block">
                            <div className="content-inner">
                                <h6 className="sub-heading primary-light-color">CUSTOMER DATA PLATFORM</h6>
                                <h4 className="h3 mb-24">Put your customer data to work</h4>
                                <p className="mb-48">Enable your business to capture customer behaviors and signals across systems to understand and create meaningful customer journeys at scale.</p>
                                <h6 className="sub-heading font-x-light-color">POPULAR FEATURES</h6>
                                <ul className="list-style-none feature-list mb-32">
                                    <li>
                                        <i class="icon"><FeaturesFolderIcon /></i><span>Unify Customer Data</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesRefreshCircleIcon /></i><span>Customer 360</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesSmartSegmentationIcon /></i><span>Smart Segmentation</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesShieldSecurityIcon /></i><span>Robust Data Security</span>
                                    </li>
                                </ul>
                                <Link className="btn-link right-arrow" to="/journey/">Learn more</Link>
                            </div>
                        </div>
                        <div className="right-block">
                            <img loading="lazy" src={PersonalizedWidgetImage} title="Personalized Widget Image" alt="Personalized Widget Image" />
                        </div>
                    </div>

                    <div className="content-with-image d-flex align-v-center mb-208">
                        <div className="left-block">
                            <div className="content-inner">
                                <h6 className="sub-heading primary-light-color">CUSTOMER JOURNEY AUTOMATION</h6>
                                <h4 className="h3 mb-24">Orchestrate automated and personalized customer journeys</h4>
                                <p className="mb-48">Grow revenue, relationships, and conversion with multi-touchpoint, multi-day, multi-channel customer journeys that nurture and engage with customer data, analytics, AI, and marketing automation.</p>
                                <h6 className="sub-heading font-x-light-color">POPULAR FEATURES</h6>
                                <ul className="list-style-none feature-list mb-32">
                                    <li>
                                        <i class="icon"><FeaturesBuilderIcon /></i><span>Drag-n-Drop Journey Builder</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesShareIcon /></i><span>Omni-channel Behavior Engagement</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesChartIcon /></i><span>Data Automation</span>
                                    </li>
                                    <li>
                                        <i className="icon"><FeaturesMouseIcon /></i><span>Triggers, Conditions and Actions</span>
                                    </li>
                                </ul>
                                <Link className="btn-link right-arrow" to="/journey/#marketing-automation">Learn more</Link>
                            </div>
                        </div>
                        <div className="right-block">
                            <img loading="lazy" src={PersonalizedWidgetAutomation} title="Product Boost Overview" alt="Product Boost Overview" />
                        </div>
                    </div>
                </div>

                <div className="icon-with-text-block mb-208">
                    <div className="container">
                        <div className="custom-row d-flex row-3">
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <TargetIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Fast and easy to launch</h6>
                                        <p className="small-text">Rely on the high-performant eCommerce store to deliver desired experience and content within milliseconds.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <MultiWorkspaceIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Works with the existing stack</h6>
                                        <p className="small-text">Leverage robust APIs building blocks to work with the current tech stack and avoid building from scratch.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <DesignIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Built it your way</h6>
                                        <p className="small-text">Design composable B2C or B2B buying experiences that your customers crave with true omnichannel flexibility.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-order-successfully-block mb-208">
                    <div className="container">
                        <div className="custom-row d-flex align-v-center row-2">
                            <div className="grid">
                                <h4 className="h2 mb-24">Turn visitors into customers and customers into brand advocates.</h4>
                                <p className="mb-80">Boost eCommerce growth with enhanced “Product Discovery” features that help your team take control of product display with an AI-powered search algorithm and site-wide merchandising campaigns.</p>
                                <div className="count-block">
                                    <ul className="list-style-none">
                                        <li>
                                            <strong className="h3 line-height-60 link-color">60%</strong>
                                            <span className="font-capital">Increase in eCommerce Conversion</span>
                                        </li>
                                        <li>
                                            <strong className="h3 line-height-60 link-color">5X</strong>
                                            <span className="font-capital">Faster PAGE load time</span>
                                        </li>
                                        <li>
                                            <strong className="h3 line-height-60 link-color">13%</strong>
                                            <span className="font-capital">Increase in average order value </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="image-block">
                                    <img loading="lazy" src={OrderSuccessfullyImg} title="Order Successfully" alt="Order Successfully" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
